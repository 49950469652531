<template>
  <div class="text-center">
    <p class="text-center">
      The following error occurred:
    </p>
    <div class="alert alert-secondary">
      {{ message | striphtml }}
    </div>
    <p>
      <router-link class="btn-link" to="{name: 'Login'}">
        Back to Log in
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OAuthConfirm',
  data() {
    return {
      message: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    // make sure have necessary oauth state
    if (!to.query.message) {
      next({name: 'Login'});
    } else {
      next(vm => {
        vm.$data.message = to.query.message;
      });
    }
  }
};
</script>
